/* .base {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
  overflow: hidden;
}

.content, .bottomFade {
  position: relative;
  margin: auto;
  max-width: 750px;
  }
  
  .bottomFade {
    background: linear-gradient(180deg, #000 39.13%, rgba(0, 0, 0, 0.00) 100%);
    height: 5dvh;
    z-index: 10;
    } */
    
.content {
  position: relative;
  margin: auto;
}
