/* date cell */
.rbc-day-slot {
  min-width: calc((100vw/4) - 30px) !important;
  margin-right: 0 !important;
}

/* resource header bar */
.rbc-time-header-content {
  position: relative;
  min-width: calc(100vw/4 - 30px) !important;
}

.rbc-time-header.rbc-overflowing {
  border-right: none !important;
}

.rbc-time-content{
  border-top: 1px solid #f4f4f5;
 }

/* main calendar */
.rbc-timeslot-group {
  border-color: #f4f4f5;
  background-color: white;
}

.rbc-calendar {
  height: 100vh;
  padding-bottom: 10px;
}

.rbc-time-view {
  border: none;
  width: calc(100vw - 64px);
 }

.rbc-time-view-resources .rbc-time-gutter {
  border-color: #f4f4f5;
}

/* event */
.rbc-event {
  background-color: transparent !important;
  border: none !important;
  overflow: visible !important;
  outline: none !important;
}

.rbc-event:has(.overlapping) {
  width: 100% !important;
}

.rbc-event-label {
  display: none;
}

/* Indicator */
.rbc-current-time-indicator {
  background-color: #FC8585;
}

.rbc-events-container {
  cursor: pointer;
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}