@import "react-toastify/dist/ReactToastify.min.css";

* {
  font-family: 'Inter', 'IBM Plex Serif', sans-serif;
  font-weight: 400;
}

button, input, textarea {
  user-select: none !important;
}

/* span {
  user-select: text !important;
} */

/* html,
body {
  overscroll-behavior: none;
} */