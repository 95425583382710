@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Inter:wght@100;200;300;400;500;600;700&family=IBM+Plex+Serif:wght@100;200;300;400;500;600;700&display=swap');

/* body {
  overscroll-behavior-x: none;
} */

* {
  -webkit-box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

button {
  @apply !min-w-0 !select-all;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

/* pl-3 text-sm bg-transparent rounded text-zinc-500 shadow-sm border-[#F4F4F5] h-10 placeholder:text-zinc-500 border-1 outline-none focus:border-black active:border-black hover:border-zinc-500 */

.custom-input {
  @apply w-full pl-3 text-sm !bg-transparent rounded shadow-sm border-[#F4F4F5] h-10 font-semibold text-[#3F3F46] border-1 outline-none focus:border-black active:border-black hover:border-zinc-500 placeholder:font-normal placeholder:text-zinc-500;
}

.css-13cymwt-control {
  @apply !rounded !shadow-sm !border-[#F4F4F5] !cursor-pointer !text-[#3F3F46] !border-1 focus:!border-black active:!border-black hover:!border-zinc-500 placeholder:!font-normal placeholder:!text-zinc-500
}

/* input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */
